
import Editor from '@tinymce/tinymce-vue'
import TranslationUtil from '@/utils/TranslationUtil'

export default {
  components: {
    'editor': Editor
  },
  emits: ['update'],
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
    isRichText: {
      type: Boolean,
      required: false,
    },
    inlineBlock: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
    onUpdate() {
      this.$emit('update', this.translationsCopy);
      this.dialogOpened = false;
      this.dialogContentShown = !this.isRichText;
    },

    openDialog() {
      this.dialogContentShown = !this.isRichText;
      this.dialogOpened = true;
      this.translationsCopy = JSON.parse(JSON.stringify(this.translations));

      for (const lang of this.supportedLanguages) {
        if (!this.translationsCopy[lang]) {
          this.translationsCopy[lang] = {};
        }
      }

      setTimeout(() => {
        this.dialogContentShown = true;
      }, 100)
    }
  },
  data() {
    const copyObject = {};
    const supportedLanguages = TranslationUtil.getSupportedLanguages();

    for (const lang of supportedLanguages) {
      if (!copyObject[lang]) {
        copyObject[lang] = {};
      }
    }

    return {
      dialogOpened: false,
      dialogContentShown: false,
      translationsCopy: copyObject,
      supportedLanguages,
    };
  },
}

