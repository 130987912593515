
export default class TranslationUtil {
  static getSupportedLanguages() {
    return ['en', 'it'];
  }

  static transformToRequestDto(translationsResponse, defaultValue = {}) {
    const result = {};

    this.getSupportedLanguages().forEach(lang => {
      result[lang] = { ...defaultValue };
    });

    if (Array.isArray(translationsResponse)) {
      translationsResponse.forEach(translation => {
        if (result[translation.language]) {
          Object.assign(result[translation.language], translation.values);
        }
      })
    }

    return result;
  }
}
